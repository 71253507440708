<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
  >
    <template>
      <v-card>
        <v-card-text v-if="isInCart">
          Checked items are already in your cart
        </v-card-text>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        hide-default-footer
        hide-default-header
        item-key="sku"
        show-select
        dense
        class="elevation-0"
        @item-selected="selectItem"
      >
        <template v-slot:[`item.unitPrice`]="{ item }">
          <span class="mx-2">{{ `£${item.unitPrice.toFixed(2)}` }}</span>
        </template>
        <template v-slot:[`item.qty`]="{ item }">
          <div class="d-flex" v-if="selected.findIndex(thisitem => thisitem === item) >= 0">
          <v-icon small @click="decrement(item)">
            mdi-minus
          </v-icon>
          <span class="mx-2">{{ item.qty }}</span>
          <v-icon small @click="increment(item)">
            mdi-plus
          </v-icon>
          </div>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-btn :disabled="!isDirty" color="primary" @click="addProductToCart" >
          <v-icon
            left
            dark
          >
            mdi-cart-outline
          </v-icon>
          Update

        </v-btn>
        <v-btn color="secondary" @click="close" >
          Close
        </v-btn>
      </v-card-actions>
      </v-card>
    </template>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" v-bind="attrs" v-on="on">
        Select Variant
      </v-btn>
    </template>

  </v-menu>
</template>

<script>
import variantText from '@/constants/productVariants'
export default {
  props: {
    item: Object,
    cart: Object
  },
  data () {
    return {
      selected: [],
      headers: [
        { text: 'Variant', value: 'sku' },
        { text: 'Description', value: 'description' },
        { text: 'Size', value: 'size' },
        { text: 'Price', value: 'unitPrice' },
        { text: 'Quantity', value: 'qty' }
      ],
      items: this.item.variants.map((item) => {
        return {
          sku: item.sku,
          description: item.variantName,
          size: item.imageWidth ? `(print size ${item.imageWidth} x ${item.imageHeight} )` : item.dimensions,
          unitPrice: item.unitPrice,
          qty: this.item.minQty
        }
      }),
      menu: false,
      isDirty: false
    }
  },
  computed: {
    isInCart () {
      return this.selected.length > 0
    }
  },
  created: function () {
    this.cart.items.map((cartItem) => {
      if (this.item.variants.findIndex(item => item.sku === cartItem.sku) > -1) {
        this.selected.push({
          sku: cartItem.sku,
          unitPrice: cartItem.unitPrice,
          qty: this.item.minQty
        })
      }
    })
  },
  methods: {
    decrement (item) {
      if (item.qty > this.item.minQty) {
        item.qty -= this.item.multQty
        this.isDirty = true
      }
    },
    increment (item) {
      item.qty += this.item.multQty
      this.isDirty = true
    },
    addProductToCart () {
      this.$store.dispatch('cart/updateCartItems',  this.selected)
      // this.$emit('addProduct', this.selected)
      this.isDirty = false
    },
    selectItem(evt) {
      !evt.value && this.$store.dispatch('cart/removeItem', evt.item)
      this.isDirty = true
    },
    close () {
      this.menu = false
    }
  }
}
</script>
<style lang="scss" scoped>
.v-menu__content {
  z-index: 4 !important
}
</style>
