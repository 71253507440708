<template>
  <v-img
    :max-width="`${size}px`"
    max-height="90vh"
    :src="`${imageUrl}/${imageSize}px/${getImageToDisplay(imageName())}`"
    contain
    @error="missingImage"
  ></v-img>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    size: {
      type: Number,
      default: () => {
        return 300
      }
    },
    imageSize: {
      type: Number,
      default: () => {
        return 300
      }
    },
    item: Object
  },
  data () {
    return {
      imageUrl: process.env.VUE_APP_IMG_URL,
      imageName: () => {
        switch (this.item.category) {
          case 'Open Edition Giclee':
          case 'Limited Edition Giclee':
            return this.findColourVariant(this.filters.imageColour, this.item)[0]
            // return this.filters.imageColour && this.filters.imageColour.length && this.item.colourVariants[0][this.filters.imageColour[1]] ? this.item.colourVariants[0][this.filters.imageColour[0]][1] : this.item.images[1]
          default:
            return this.findColourVariant(this.filters.imageColour)[0]
          // return this.filters.imageColour && this.filters.imageColour.length && this.item.colourVariants[0][this.filters.imageColour[0]] ? this.item.colourVariants[0][this.filters.imageColour[0]][0] : this.item.images[0]
        }
      }
    }
  },
  computed: mapState({
    filters: (state) => state.filters.all,
    computedImageName: {
      get: function () {
        switch (this.item.category) {
          case 'Open Edition Giclee':
          case 'Limited Edition Giclee':
            return this.findColourVariant(this.filters.imageColour, this.item)[0]
            // return this.filters.imageColour && this.filters.imageColour.length && this.item.colourVariants[0][this.filters.imageColour[1]] ? this.item.colourVariants[0][this.filters.imageColour[0]][1] : this.item.images[1]
          default:
            return this.findColourVariant(this.filters.imageColour)[0]
          // return this.filters.imageColour && this.filters.imageColour.length && this.item.colourVariants[0][this.filters.imageColour[0]] ? this.item.colourVariants[0][this.filters.imageColour[0]][0] : this.item.images[0]
        }
      },
      set: (val) => {
        return val
      }
    }
  }),
  methods: {
    missingImage () {
      this.imageName = () => 'awaiting_image.jpg'
      // this.$forceUpdate()
    },
    findColourVariant: function (colour) {
      const variant = this.item.variantColours.find(obj => obj.imageColour === colour)
      if (
        this.filters.imageColour &&
        this.filters.imageColour.length && variant
      ) {
        return variant.images
      } else return this.item.variants[0].images
    }
  }
}
</script>
